import './css/app.scss';
import './js/datatable';
// import './js/modal';
// import live from './js/live.js';
// import {popupConferma, toast, alert} from "./js/swal";
import {Offcanvas, Tooltip} from "bootstrap"
import 'masonry-layout'

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
});

// import './js/confirm';
// import './js/select2';

const offcanvasElementList = document.querySelectorAll('.offcanvas')
const offcanvasList = [...offcanvasElementList].map(offcanvasEl => new Offcanvas(offcanvasEl))
